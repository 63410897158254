<template>
  <section class="section thanks">
    <div class="container">
      <div class="columns is-mobile is-centered">
        <div
          class="column is-two-thirds-mobile is-half-tablet is-one-third-desktop"
        >
          <Together />
          <h1 class="is-size-1 has-text-centered">Bedankt!</h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import confetti from 'canvas-confetti'
import Together from '@/components/Icons/Together.vue'

export default {
  data() {
    return {
      // naam: this.$route.query.naam
    }
  },
  mounted() {
    confetti({
      particleCount: 150,
      spread: 180,
      colors: ['#21897E', '#A3C9A8', '#E6EFE9', '#E6EFE9', '#00BD9D'],
      ticks: 400,
      shapes: ['square']
      // startVelocity: 30
      //   origin: {
      //     x: 1
      //   }
    })
  },
  components: {
    Together
  }
}
</script>
